import { combineReducers} from 'redux';
import { reducer as formReducer } from 'redux-form';

import datesReducer from "./datesReducer";
import guiReducer from "./guiReducer";

export default combineReducers({
    dateTimeInfo: datesReducer,
    gui: guiReducer,
    form: formReducer
});
