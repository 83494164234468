import '../sass/BookingWidget.sass';

import React, {Component} from "react";
import { connect } from 'react-redux';

import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/de';

import DayPicker from 'react-day-picker';



import { fetchSlots,fetchSettings, selectDate,setRoot } from "../actions";
import TimePicker from "./TimePicker";
import BookingInfo from "./BookingInfo";
import Form from "./Form";

class App extends Component {
    constructor(props){
        super(props);

        this.baseUrl = props.apiUrl;
        this.logo = props.logoUrl;
    }


    componentDidMount() {
        this.props.setRoot(this.baseUrl);
        this.props.fetchSlots();
        this.props.fetchSettings();
    }

    renderDateTimePicker()
    {
        if(this.props.confirmedTime !== null) return null;

        const available = [];
        const disabled = [];

        this.props.slots.forEach(slot => {
            if(slot.slots.length > 0) // We have a free slot here!
            {
                available.push(new Date(slot.date));
            }
            else
            {
                disabled.push(new Date(slot.date));
            }
        });

        const modifiers = {
            available: available,
        };

        const before = available[0];
        const after = available[available.length-1];

        const disabledDays = [...disabled, {
            after: after,
            before: before,
        }];

        return (
            <div className='date-time-picker__container'>
                <h3>Datum & Uhrzeit wählen</h3>
                <div className='date-time-picker'>
                    <div className={'date-picker'}>
                        <h3>Wählen Sie einen Tag aus</h3>
                        <DayPicker
                            modifiers={modifiers}
                            locale={'de'}
                            localeUtils={MomentLocaleUtils}
                            onDayClick={this.handleDayClick}
                            selectedDays={this.props.date}
                            // showOutsideDays
                            disabledDays={disabledDays}
                            fromMonth={before}
                            toMonth={after}
                        />
                    </div>
                    <div className={'time-picker'}>
                        <h3>Wählen Sie eine Zeit aus</h3>
                        <TimePicker/>
                    </div>
                </div>
            </div>
        );
    }

    renderForm() {
        if(this.props.gui.form === false) return null;
        return (
            <Form />
        );
    }

    renderThanks() {
        if(this.props.gui.success === false) return null;
        return (
            <div className={'booking-message'}>
                <h1>Vielen Dank für Ihr Interesse</h1>
                <p>In den nächsten Minuten erhalten Sie eine Bestätigung Ihres Termins per Mail.</p>
                <p>Einige Tage vor Ihrem Beratungstermin erinnern wir Sie selbstverständlich nochmals per Mail.</p>
                <p>Wir freuen uns auf Ihren Besuch, Ihr Team von {this.props.settings.org_address_1}</p>
            </div>
        );
    }

    renderError() {
        if(this.props.gui.error === false) return null;
        return (
            <div className={'booking-message'}>
                <h1>Das hat leider nicht funktioniert</h1>
                <p>Bitte nehmen Sie Kontakt per Telefon {this.props.settings.org_phone} oder E-Mail {this.props.settings.org_mail} zu uns auf.</p>
                <p>Wir freuen uns auf Ihren Besuch, Ihr Team von {this.props.settings.org_address_1}</p>
            </div>
        );
    }

    render () {
        return (
            <div className={'booking-widget__container'}>
                <div className={`pageloader is-primary ${this.props.slots.length === 0?'is-active':''}`}>
                    <span className="title">
                        <figure className='image'>
                            <img src={this.logo} alt={'logo'} className={'logo'} style={{maxWidth: 255}}/>
                        </figure>
                    </span>
                </div>
                <div className={`booking-widget ${this.props.gui.expanded ? 'expand':''}`}>
                    <BookingInfo />
                    {this.renderDateTimePicker()}
                    {this.renderForm()}
                </div>
            </div>
        );
    }

    handleDayClick = (day, { disabled }) => {
        if(!disabled)
        {
            this.props.selectDate(day);
        }
    }
}

const mapStateToProps = (state) => {
    return {
        slots: state.dateTimeInfo.slots,
        date: state.dateTimeInfo.selectedDate,
        slot: state.dateTimeInfo.selectedTime,
        confirmedTime: state.dateTimeInfo.confirmedTime,
        gui: state.gui,
        settings: state.gui.settings
    }
};

export default connect(mapStateToProps, {fetchSlots,fetchSettings, selectDate,setRoot})(App);